import React from 'react';


function Success() {


  return (

	
		  <main>
			<div align="center" style={{paddingTop:"40px"}}>
				<h3>Success</h3>
			</div>
			 	
				
		  </main>
	  
  )
}

export default Success;
